export default [
  /* User */
  {
    path: '/master/user_web',
    name: 'master-user-web',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-user',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'User Web',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/user_mobile',
    name: 'master-user-mobile',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-user',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'User Mobile',
          active: true,
        },
      ],
    },
  },
  /* Store */
  {
    path: '/master/store',
    name: 'master-store',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Store',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/store_type',
    name: 'master-store-type',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Store Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/rtm_level5',
    name: 'master-rtm-level5',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'RTM Level 5',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/rtm_level4',
    name: 'master-rtm-level4',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Channel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/rtm_level3',
    name: 'master-rtm-level3',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Sub Channel',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/rtm_level2',
    name: 'master-rtm-level2',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Account',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/geographic_level5',
    name: 'master-geographic-level5',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Geographic Level 5',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/geographic_level4',
    name: 'master-geographic-level4',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Geographic Level 4',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/geographic_level3',
    name: 'master-geographic-level3',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Region',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/geographic_level2',
    name: 'master-geographic-level2',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Area',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location_provinsi',
    name: 'master-province',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Province',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location_kotakabupaten',
    name: 'master-district',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'District',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location_kecamatan',
    name: 'master-sub-district',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Sub District',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location_kelurahan',
    name: 'master-urban',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store',
        },
        {
          text: 'Urban District',
          active: true,
        },
      ],
    },
  },

  /* SKU */
  {
    path: '/master/sku',
    name: 'master-sku',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'SKU',
        },
        {
          text: 'SKU',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/sku_competitor',
    name: 'master-sku-competitor',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'SKU',
        },
        {
          text: 'SKU Competitor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/sku_mapping',
    name: 'master-sku-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product-mapping',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'SKU',
        },
        {
          text: 'SKU Mapping',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/sku_so_mapping',
    name: 'master-sku-so-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product-so',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'SKU',
        },
        {
          text: 'SKU Sales Order Mapping',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/product_level4',
    name: 'master-product-level4',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Product',
        },
        {
          text: 'Division',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/product_level3',
    name: 'master-product-level3',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Product',
        },
        {
          text: 'Category',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/product_level2',
    name: 'master-product-level2',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Product',
        },
        {
          text: 'Brand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/produsen',
    name: 'master-produsen',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Product',
        },
        {
          text: 'Produsen',
          active: true,
        },
      ],
    },
  },

  /* Promo */
  {
    path: '/master/promo',
    name: 'master-promo',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-promo',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Promo',
        },
        {
          text: 'Promo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/promo_property',
    name: 'master-promo-property',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-promo',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Promo',
        },
        {
          text: 'Promo Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/promo_config',
    name: 'master-promo-config',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-promo',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Promo',
        },
        {
          text: 'Promo Config',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/promo_mapping',
    name: 'master-promo-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-promo',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Promo',
        },
        {
          text: 'Promo Mapping',
          active: true,
        },
      ],
    },
  },

  /* Activation */
  {
    path: '/master/activation',
    name: 'master-activation',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-activation',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Activation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/journey_plan',
    name: 'master-store-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-store-mapping',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Store Mapping',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/product-mapping',
    name: 'master-product-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-product-mapping',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Product Mapping',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/promo-mapping',
    name: 'master-promo-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-promo-mapping',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Promo Mapping',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/activation-mapping',
    name: 'master-activation-mapping',
    component: () => import('@/views/master/Master.vue'),
    meta: {
      pageTitle: 'Master',
      layout: 'vertical',
      // ACCESS CONTROL
      action: 'read',
      resource: 'master-activation-mapping',
      breadcrumb: [
        {
          text: 'Master',
        },
        {
          text: 'Activation Mapping',
          active: true,
        },
      ],
    },
  },
]
