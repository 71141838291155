import useJwt from '@/auth/jwt/useJwt'
import { decrypt, encrypt } from '@core/utils/utils'
import { initialAbility } from '@/libs/acl/config'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => {
  try {
    return JSON.parse(decrypt(localStorage.getItem('signature')))
  } catch (e) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('signature')

    return false
  }
}

export const decryptSSO = text => {
  try {
    return JSON.parse(decrypt(text))
  } catch (e) {
    return false
  }
}

export const encryptSSO = text => {
  try {
    return encrypt(text)
  } catch (e) {
    console.log(e)
    return false
  }
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'pic-admin') return '/'
  if (userRole === 'not-verified') return { name: 'billing' }
  if (userRole === 'finance') return { name: 'finance' }
  return { name: 'auth-login' }
}
