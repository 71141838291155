import mock from '@/@fake-db/mock'
import { numberWithCommas, paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const fieldForce = [{ value: 'select_value', text: 'Select Value' },
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
  { value: '150', text: '150' },
  { value: '200', text: '200' },
  { value: '300', text: '300' },
  { value: '500', text: '500' }]
const store = [{ value: 'select_value', text: 'Select Value' },
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
  { value: '150', text: '150' },
  { value: '200', text: '200' },
  { value: '300', text: '300' },
  { value: '500', text: '500' }]
const product = [{ value: 'select_value', text: 'Select Value' },
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '25', text: '25' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
  { value: '150', text: '150' },
  { value: '200', text: '200' },
  { value: '300', text: '300' },
  { value: '500', text: '500' }]
const priceList = [{
  title: 'Field Force',
  masterDescription: 'Total Field Force',
  rawRate: '10000',
  rate: numberWithCommas('10000'),
  total: '0',
  price: '0',
  priceRaw: 0,
},
{
  title: 'Store',
  masterDescription: 'Total Store',
  rawRate: '10000',
  rate: numberWithCommas('10000'),
  total: '0',
  price: '0',
  priceRaw: 0,

},
{
  title: 'Product',
  masterDescription: 'Total Product',
  rawRate: '10000',
  rate: numberWithCommas('10000'),
  total: '0',
  price: '0',
  priceRaw: 0,
}]
const brandName = [
  { value: 1, text: 'Royco', parent_id: 1 },
  { value: 2, text: 'Pepsodent', parent_id: 2 }]
const categoryName = [
  { value: 1, text: 'Savoury', parent_id: 0 },
  { value: 2, text: 'Home Care' }]
const setupList = [
  {
    name: 'billing',
    title: 'Billing',
    cardTitle: 'Billings',
    total: 10,
    totalActive: 8,
    fieldColumns: [
      { key: 'invoice', label: 'Invoice', sortable: true },
      { key: 'issued', label: 'Issued', sortable: true },
      { key: 'qty', label: 'QTY', sortable: true },
      { key: 'total', label: 'Total', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'category',
        label: 'Category',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      }, {
        id: 2,
        sort_number: 2,
        parent_id: 1,
        name: 'brand',
        label: 'Brand',
        type: 'select',
        source: '/master/brand/getData',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'product_code',
        label: 'Product Code',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      }, {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'product_name',
        label: 'Product Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'product_packshot',
        label: 'Product Packshot',
        labelEdit: 'Product Packshot (Abaikan apabila tidak ingin merubah)',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      }, {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 7,
        sort_number: 7,
        parent_id: 0,
        name: 'periode_start',
        label: 'Periode Start',
        type: 'date',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 8,
        sort_number: 8,
        parent_id: 0,
        name: 'periode_end',
        label: 'Periode End',
        type: 'date',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }],
    insertEndpoint: '',
    editEndpoint: '/billing/edit',
    deleteEndpoint: '/billing/delete',
    getDataEndpoint: '/billing/getDataTable/',
    getDetailEndpoint: '/billing/getDetail/',
    importEndpoint: '',
    exportEndpoint: '/billing/export',
    templateEndpoint: '',
  },
  {
    name: 'category',
    title: 'Category',
    cardTitle: 'Categories',
    total: 10,
    totalActive: 8,
    fieldColumns: [
      { key: 'category', label: 'Category', sortable: true },
      { key: 'photo_row_size', label: 'Row Size' },
      { key: 'photo_column_size', label: 'Column Size' },
      { key: 'image_path', label: 'Image Path' },
      {
        key: 'is_required', label: 'Required', sortable: true, class: 'text-center',
      },
      {
        key: 'is_active', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'category',
        label: 'Category',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'photo_row_size',
        label: 'Row Size',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'photo_column_size',
        label: 'Column Size',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'image_path',
        label: 'Image Path',
        labelEdit: 'Image Path (Abaikan apabila tidak ingin merubah)',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      },
      {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'is_required',
        label: 'Required',
        type: 'boolean',
        source: [{ value: 0, text: 'Optional' },
          { value: 1, text: 'Required' }],
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/product/category/insert',
    editEndpoint: '/master/product/category/edit',
    deleteEndpoint: '/master/product/category/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/product/category/import',
    exportEndpoint: '/master/product/category/export',
    templateEndpoint: '/master/product/category/template',
  },
  {
    name: 'brand',
    title: 'Brand',
    cardTitle: 'Brands',
    total: 10,
    totalActive: 8,
    fieldColumns: [
      { key: 'category', label: 'Category', sortable: true },
      { key: 'brand_code', label: 'Brand Code', sortable: true },
      { key: 'brand_name', label: 'Brand Name', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'category',
        label: 'Category',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 1,
        name: 'brand_code',
        label: 'Brand Code',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 1,
        name: 'brand_name',
        label: 'Brand Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/product/brand/insert',
    editEndpoint: '/master/product/brand/edit',
    deleteEndpoint: '/master/product/brand/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/product/brand/import',
    exportEndpoint: '/master/product/brand/export',
    templateEndpoint: '/master/product/brand/template',
  },
  {
    name: 'product',
    title: 'Product',
    cardTitle: 'Products',
    total: 200,
    totalActive: 150,
    fieldColumns: [
      { key: 'product_code', label: 'Product Code', sortable: true },
      { key: 'brand_code', label: 'Brand Code', sortable: true },
      // { key: 'total', sortable: true, formatter: val => `$${val}` },
      { key: 'brand_name', label: 'Brand Name', sortable: true },
      { key: 'category', label: 'Category', sortable: true },
      { key: 'product_name', label: 'Product Name', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'category',
        label: 'Category',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 2,
        sort_number: 2,
        parent_id: 1,
        name: 'brand',
        label: 'Brand',
        type: 'select',
        source: '/master/brand/getData',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'product_code',
        label: 'Product Code',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      }, {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'product_name',
        label: 'Product Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'product_packshot',
        label: 'Product Packshot',
        labelEdit: 'Product Packshot (Abaikan apabila tidak ingin merubah)',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      }, {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 7,
        sort_number: 7,
        parent_id: 0,
        name: 'periode_start',
        label: 'Periode Start',
        type: 'date',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }, {
        id: 8,
        sort_number: 8,
        parent_id: 0,
        name: 'periode_end',
        label: 'Periode End',
        type: 'date',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      }],
    insertEndpoint: '/master/product/insert',
    editEndpoint: '/master/product/edit',
    deleteEndpoint: '/master/product/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/product/import',
    exportEndpoint: '/master/product/export',
    templateEndpoint: '/master/product/template',
  },
  {
    name: 'user',
    title: 'User',
    cardTitle: 'Users',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'user_id', label: 'User ID', sortable: true },
      { key: 'username', label: 'Username', sortable: true },
      { key: 'name', label: 'Name', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'phone', label: 'Phone', sortable: true },
      { key: 'gender', label: 'Gender', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'user_id',
        label: 'User ID',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'username',
        label: 'Username',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'name',
        label: 'Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'email',
        label: 'Email',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'phone',
        label: 'Phone',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 25,
        },
      },
      {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'gender',
        label: 'Gender',
        type: 'boolean',
        source: [{ value: 0, text: 'Male' },
          { value: 1, text: 'Female' }],
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 7,
        sort_number: 7,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/user/insert',
    editEndpoint: '/master/user/edit',
    deleteEndpoint: '/master/user/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/user/import',
    exportEndpoint: '/master/user/export',
    templateEndpoint: '/master/user/template',
  },
  {
    name: 'promo',
    title: 'Promo',
    cardTitle: 'Promos',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'promo_code', label: 'Promo Code', sortable: true },
      { key: 'reference_code', label: 'Reference Code', sortable: true },
      { key: 'promo_name', label: 'Promo Name', sortable: true },
      { key: 'promo_mechanism', label: 'Description', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'promo_code',
        label: 'Promo Code',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'reference_code',
        label: 'Reference Code',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'promo_name',
        label: 'Promo Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'promo_mechanism',
        label: 'Description',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/promo/insert',
    editEndpoint: '/master/promo/edit',
    deleteEndpoint: '/master/promo/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/promo/import',
    exportEndpoint: '/master/promo/export',
    templateEndpoint: '/master/promo/template',
  },
  {
    name: 'activation',
    title: 'Activation',
    cardTitle: 'Activations',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'posm_name', label: 'Activation Name', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'posm_name',
        label: 'Activation Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/activation/insert',
    editEndpoint: '/master/activation/edit',
    deleteEndpoint: '/master/activation/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/activation/import',
    exportEndpoint: '/master/activation/export',
    templateEndpoint: '/master/activation/template',
  },
  {
    name: 'store',
    title: 'Store',
    cardTitle: 'Stores',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'store_code', label: 'Store Code', sortable: true },
      { key: 'reference_code', label: 'Reference Code', sortable: true },
      { key: 'store_name', label: 'Store Name', sortable: true },
      { key: 'address', label: 'Address', sortable: true },
      { key: 'latitude', label: 'Latitude', sortable: true },
      { key: 'longitude', label: 'Longitude', sortable: true },
      { key: 'store_type', label: 'Longitude', sortable: true },
      { key: 'rtm_level2_name', label: 'Longitude', sortable: true },
      { key: 'geographic_level2_name', label: 'Longitude', sortable: true },
      { key: 'kelurahan', label: 'Longitude', sortable: true },
      { key: 'image_path', label: 'Longitude', sortable: true },
      { key: 'last_visited_datetime', label: 'Longitude', sortable: true },
      { key: 'last_visited_by', label: 'Longitude', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'store_code',
        label: 'Store Code',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'reference_code',
        label: 'Reference Code',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'store_name',
        label: 'Store Name',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'address',
        label: 'Address',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'latitude',
        label: 'Latitude',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'longitude',
        label: 'Longitude',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 7,
        sort_number: 7,
        parent_id: 0,
        name: 'store_type',
        label: 'Store Type',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 8,
        sort_number: 8,
        parent_id: 0,
        name: 'rtm_level2_name',
        label: 'Account',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 9,
        sort_number: 9,
        parent_id: 0,
        name: 'geographic_level2_name',
        label: 'Area',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 10,
        sort_number: 10,
        parent_id: 0,
        name: 'kelurahan',
        label: 'Kelurahan',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 11,
        sort_number: 11,
        parent_id: 0,
        name: 'image_path',
        label: 'Image',
        labelEdit: 'Image Path',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      },
      {
        id: 12,
        sort_number: 12,
        parent_id: 0,
        name: 'last_visited_datetime',
        label: 'Last Visited',
        type: 'datetime',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 13,
        sort_number: 13,
        parent_id: 0,
        name: 'last_visited_by',
        label: 'Last Visited By',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 14,
        sort_number: 14,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/store/insert',
    editEndpoint: '/master/store/edit',
    deleteEndpoint: '/master/store/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/store/import',
    exportEndpoint: '/master/store/export',
    templateEndpoint: '/master/store/template',
  },
  {
    name: 'account',
    title: 'Account',
    cardTitle: 'Accounts',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'rtm_level2_name', label: 'Account Name', sortable: true },
      { key: 'rtm_level2_logo_path', label: 'Logo Path', sortable: true },
      {
        key: 'is_active', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'rtm_level2_name',
        label: 'Account',
        type: 'select',
        source: '/master/account/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'image_path',
        label: 'Image',
        labelEdit: 'Image Path',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/account/insert',
    editEndpoint: '/master/account/edit',
    deleteEndpoint: '/master/account/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/account/import',
    exportEndpoint: '/master/account/export',
    templateEndpoint: '/master/account/template',
  },
  {
    name: 'channel',
    title: 'Channel',
    cardTitle: 'Channels',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'rtm_level4_name', label: 'Channel', sortable: true },
      { key: 'rtm_level4_logo_path', label: 'Logo Path', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'rtm_level4_name',
        label: 'Account',
        type: 'select',
        source: '/master/account/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'image_path',
        label: 'Image',
        labelEdit: 'Image Path',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/channel/insert',
    editEndpoint: '/master/channel/edit',
    deleteEndpoint: '/master/channel/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/channel/import',
    exportEndpoint: '/master/channel/export',
    templateEndpoint: '/master/channel/template',
  },
  {
    name: 'area',
    title: 'Area',
    cardTitle: 'Areas',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'geographic_level2_name', label: 'Area', sortable: true },
      { key: 'geographic_level3_name', label: 'Province', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'geographic_level2_name',
        label: 'Area Name',
        type: 'select',
        source: '/master/account/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'geographic_level3_name',
        label: 'Province Name',
        type: 'select',
        source: '/master/account/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/area/insert',
    editEndpoint: '/master/area/edit',
    deleteEndpoint: '/master/area/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/area/import',
    exportEndpoint: '/master/area/export',
    templateEndpoint: '/master/area/template',
  },
  {
    name: 'province',
    title: 'Province',
    cardTitle: 'Provinces',
    total: 10,
    totalActive: 5,
    fieldColumns: [
      { key: 'geographic_level3_name', label: 'Province', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'geographic_level3_name',
        label: 'Province Name',
        type: 'select',
        source: '/master/account/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'is_active',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Inactive' },
          { value: 1, text: 'Active' }],
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/master/province/insert',
    editEndpoint: '/master/province/edit',
    deleteEndpoint: '/master/province/edit',
    getDataEndpoint: '/master/getDataTable/',
    getDetailEndpoint: '/master/getDetail/',
    importEndpoint: '/master/province/import',
    exportEndpoint: '/master/province/export',
    templateEndpoint: '/master/province/template',
  },
  {
    name: 'finance',
    title: 'Payment',
    cardTitle: 'Payments',
    total: 10,
    totalActive: 8,
    fieldColumns: [
      { key: 'payment_number', label: 'Payment Number', sortable: true },
      { key: 'payment_datetime', label: 'Date and Time', sortable: true },
      { key: 'amount', label: 'Amount', sortable: true },
      {
        key: 'status', label: 'Status', sortable: true, class: 'text-center',
      },
      { key: 'image_url', label: 'Image', sortable: true },
      { key: 'payment_type', label: 'Payment Type', sortable: true },
      { key: 'user', label: 'User', sortable: true },
      { key: 'tax_rate', label: 'Tax Rate', sortable: true },
      { key: 'tax_amount', label: 'Tax Amount', sortable: true },
      { key: 'final_amount', label: 'Total Amount', sortable: true },
      { key: 'referral_code', label: 'Referral Code', class: 'text-center' },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'payment_number',
        label: 'Payment Number',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'payment_datetime',
        label: 'Date and Time',
        type: 'date',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 3,
        sort_number: 3,
        parent_id: 0,
        name: 'amount',
        label: 'Amount',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 4,
        sort_number: 4,
        parent_id: 0,
        name: 'status',
        label: 'Status',
        type: 'boolean',
        source: [{ value: 0, text: 'Closed' },
          { value: 1, text: 'Open' }],
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 5,
        sort_number: 5,
        parent_id: 0,
        name: 'image_url',
        label: 'Image',
        labelEdit: 'Image (Ignore it, if you don\'t want to change)',
        type: 'file-multiple',
        source: '',
        width: 100,
        rules: {
          required: true,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
        rulesEdit: {
          required: false,
          ext: ['png', 'jpg', 'jpeg'],
          size: 2048,
        },
      },
      {
        id: 6,
        sort_number: 6,
        parent_id: 0,
        name: 'payment_type',
        label: 'Payment Type',
        type: 'select',
        source: '/master/category/getData',
        width: 100,
        rules: {
          max: 250,
          required: true,
        },
      },
      {
        id: 7,
        sort_number: 7,
        parent_id: 0,
        name: 'user',
        label: 'User',
        type: 'select',
        source: '/master/brand/getData',
        width: 100,
        rules: {
          required: true,
        },
      },
      {
        id: 8,
        sort_number: 8,
        parent_id: 0,
        name: 'tax_rate',
        label: 'Tax Rate',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 9,
        sort_number: 9,
        parent_id: 0,
        name: 'tax_amount',
        label: 'Tax Amount',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 10,
        sort_number: 10,
        parent_id: 0,
        name: 'final_amount',
        label: 'Total Amount',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
        },
      },
      {
        id: 11,
        sort_number: 11,
        parent_id: 0,
        name: 'referral_code',
        label: 'Referral Code',
        type: 'text',
        source: '',
        width: 100,
        rules: {
          required: true,
        },
      },
    ],
    insertEndpoint: '/finance/add',
    editEndpoint: '/finance/edit',
    deleteEndpoint: '/finance/delete',
    getDataEndpoint: '/finance/getDataTable/',
    getDetailEndpoint: '/finance/getDetail/',
    importEndpoint: '/finance/import',
    exportEndpoint: '/finance/export',
    templateEndpoint: '/finance/template',
  },
  {
    name: 'store-type',
    title: 'Store Type',
    cardTitle: 'Store Types',
    total: 2,
    totalActive: 2,
    fieldColumns: [
      {
        key: 'typeId', label: 'Type ID', sortable: true, class: 'text-center',
      },
      { key: 'typeName', label: 'Type Name', sortable: true },
      { key: 'id', label: 'Action', class: 'text-center' },
    ],
    fields: [
      {
        id: 1,
        sort_number: 1,
        parent_id: 0,
        name: 'typeId',
        label: 'Type ID',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
      {
        id: 2,
        sort_number: 2,
        parent_id: 0,
        name: 'typeName',
        label: 'Type Name',
        type: 'number',
        source: '',
        width: 100,
        rules: {
          required: true,
          integer: true,
          max: 250,
        },
      },
    ],
    insertEndpoint: '/master/store_type/insert',
    editEndpoint: '/master/store_type/edit',
    deleteEndpoint: '/master/store_type/edit',
    getDataEndpoint: '/master/store_type/all',
    getDetailEndpoint: '/master/store_type/detail',
    importEndpoint: '/master/store_type/import',
    exportEndpoint: '/master/store_type/export',
    templateEndpoint: '/master/store_type/template',
  },
]

/* eslint-disable global-require */
// mock.onGet('/console/fieldForceOption').reply(() => [200, fieldForce])
// mock.onGet('/console/storeOption').reply(() => [200, store])
// mock.onGet('/console/productOption').reply(() => [200, product])
// mock.onGet('/console/priceList').reply(() => [200, priceList])
//
// mock.onPost('/master/getSetup').reply(request => {
//   const { masterType } = JSON.parse(request.data)
//   let error = {
//     email: ['Something went wrong'],
//   }
//   try {
//     const response = setupList.find(e => e.name === masterType)
//     if (!response) return [404]
//     return [200, response]
//   } catch (e) {
//     error = e
//   }
//
//   return [400, { error }]
// })

// mock.onGet('/master/category/getData').reply(() => [200, categoryName])
// mock.onGet('/master/brand/getData').reply(() => [200, brandName])
// mock.onGet('/master/user/getData').reply(() => [200, userName])
// mock.onGet('/master/promo/getData').reply(() => [200, promoName])
// mock.onGet('/master/store/getData').reply(() => [200, storeName])
// mock.onGet('/master/activation/getData').reply(() => [200, activationName])
