import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
    cartModulesCount: (() => {
      let total = 0
      const modules = JSON.parse(localStorage.getItem('modulesCart'))
      if (modules) {
        modules.forEach(module => {
          total = module.products.reduce(
            (previousValue, currentValue) => (previousValue + (currentValue.isInCart ? 1 : 0)),
            total,
          )
        })
      }
      return total
    })(),
    priceTotal: (() => {
      let total = 0
      const modules = JSON.parse(localStorage.getItem('modulesCart'))
      let periode = 1
      let qtyVisit = 1
      if (modules) {
        qtyVisit = modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1 ).qty ?? 1
        modules.forEach(module => {
          total = module.products.reduce(
            (previousValue, currentValue) => (previousValue + (currentValue.isInCart ? (currentValue.qty * currentValue.price * (([1, 2, 3, 25].includes(currentValue.id)) ? 1 : qtyVisit)) : 0)),
            total,
          )
        })
        periode = modules.find(e => e.name === 'Coverage').products.find(e => e.name === 'Periode').qty ?? 1
      }
      return total * periode
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    UPDATE_CART_MODULES_COUNT(state, count) {
      state.cartModulesCount = count
    },
    UPDATE_PRICE_COUNT(state, price) {
      state.priceTotal = price
    },
    PERIODE_COUNT(state, periode) {
      state.priceTotal = periode
    },
  },
  actions: {
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/products', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts2(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/products2', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
