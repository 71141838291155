export default [
  {
    path: '/console/price-calculator',
    name: 'price-calculator',
    component: () => import('@/views/pages/console/price-calculator/FormWizard.vue'),
    meta: {
      resource: 'PriceCalculator',
      action: 'read',
      pageTitle: 'Price Calculator',
      breadcrumb: [
        {
          text: 'Console',
        },
        {
          text: 'Price Calculator',
          active: true,
        },
      ],
    },
  },
]
