import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const makeToast = (e, message, icon, variant) => {
  e.$toast({
    component: ToastificationContent,
    props: {
      title: message,
      icon,
      variant,
    },
  })
}

export const numberWithCommas = x => {
  if (!x) return 0
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// cart product
export const updatePrice = e => {
  let total = 0
  let count = 0
  let qtyVisit = e.modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1 ).qty ?? 1

  e.modules.forEach(module => {
    module.products.forEach(product => {
      if (product.parent_id) {
        const parent = e.modules.some(m => m.products.find(p => p.id === product.parent_id && p.isInCart))
        const isVisible = parent
        /* eslint-disable no-param-reassign */
        product.isInCart = !isVisible ? false : product.isInCart
        product.isVisible = isVisible
        /* eslint-enable */
      }
    })
    total = module.products.reduce(
      (previousValue, currentValue) => (previousValue + (currentValue.isInCart ? (currentValue.qty * currentValue.price * (([1, 2, 3, 25].includes(currentValue.id)) ? 1 : qtyVisit)) : 0)),
      total,
    )
    count = module.products.reduce(
      (previousValue, currentValue) => (previousValue + (currentValue.isInCart ? 1 : 0)),
      count,
    )
  })
  const periode = e.modules.find(e => e.name === 'Coverage').products.find(e => e.name === 'Periode').qty
  total *= periode
  store.commit('app-ecommerce/UPDATE_PRICE_COUNT', total)
  store.commit('app-ecommerce/UPDATE_CART_MODULES_COUNT', count)
  localStorage.setItem('modulesCart', JSON.stringify(e.modules))
}
