import axios from '@axios'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    masterType: '',
  },
  getters: {},
  mutations: {},
  actions: {
    fetchMasterTables(ctx, params) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        useJwt
          .jwtPost(params.endpoint, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMasterTable(ctx, params) {
      return new Promise((resolve, reject) => {
        useJwt
          .jwtGet(`${params.endpoint}/${params.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
