export default [
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/finance/Finance.vue'),
    meta: {
      pageTitle: 'Finance',
      layout: 'vertical',
      resource: 'finance',
      action: 'read',
      breadcrumb: [
        {
          text: 'Finance',
          active: true,
        },
      ],
    },
  },
]
