export default [
  {
    path: '/homepage',
    name: 'homepage',
    component: () => import('@/views/pages/homepage/Homepage.vue'),
    meta: {
      layout: 'home',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Homepage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/homepage/personal-info',
    name: 'personal-info',
    component: () => import('@/views/pages/personal-info/PersonalInfo.vue'),
    meta: {
      layout: 'home',
      resource: 'Auth',
      action: 'read',
      breadcrumb: [
        {
          text: 'Home',
          route: 'homepage',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
]
