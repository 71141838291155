import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import masterStoreModule from '@/views/master/table/invoiceStoreModule'
import billingStoreModule from '@/views/billing/table/invoiceStoreModule'
import financeStoreModule from '@/views/finance/table/invoiceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'app-master': masterStoreModule,
    'app-billing': billingStoreModule,
    'app-finance': financeStoreModule,
  },
  strict: process.env.DEV,
})
